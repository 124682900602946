<template lang="pug">
div
  div.w-100.d-flex.justify-space-between.align-start.mt-6
    v-card(:loading="isDocumentFromLoading" elevation="1").w-50.pa-4
      v-card-title {{ $t('fromDocument') }}:
      v-card-text(v-if="documentFrom").pa-0
        SailorQualificationInfo(v-if="type === 'qualification-documents'" :sailorDocument="documentFrom").pa-0
        SailorEducationInfo(v-else :sailorDocument="documentFrom").pa-0

    v-icon(color="blue" x-large).my-auto.mx-6 mdi-arrow-right-circle-outline

    v-card(:loading="isDocumentToLoading" elevation="1").w-50.pa-4
      v-card-title {{ $t('toDocument') }}:
      v-card-text(v-if="documentTo").pa-0
        SailorQualificationInfo(v-if="type === 'qualification-documents'" :sailorDocument="documentTo").pa-0
        SailorEducationInfo(v-else :sailorDocument="documentTo").pa-0

  v-btn(@click="mergeDocuments" :loading="isDocumentsMerging" color="success").mt-6 {{ $t('save') }}
</template>

<script>
import SailorQualificationInfo from '@/views/Sailor/SailorQualification/Info.vue'
import { checkAccess } from '@/mixins/permissions'
import { mapActions } from 'vuex'

export default {
  props: {
    sailorID: { type: [String, Number], required: true },
    type: { type: String, required: true },
    documents: { type: Array, required: true },
    qualificationType: { type: Number }
  },
  components: {
    SailorEducationInfo: () => import('@/views/Sailor/SailorEducation/SailorEducationInfo/Info.vue'),
    SailorQualificationInfo
  },
  data () {
    return {
      documentFrom: null,
      documentTo: null,
      isDocumentFromLoading: false,
      isDocumentToLoading: false,
      isDocumentsMerging: false,
      checkAccess
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'home', access: checkAccess('documents-merging') })
  },
  beforeMount () {
    if (!this.documents || !this.sailorID || !this.type) return this.$router.go(-1)
    this.documents.sort((a, b) => a > b ? 1 : -1)
    this.loadDocumentsForMerge()
  },
  methods: {
    ...mapActions(['getQualificationDiplomaProofByID', 'getQualificationDocumentsByID', 'getEducationDocumentByID']),

    loadDocumentsForMerge () {
      // Load document FROM
      this.isDocumentFromLoading = true
      if (this.type === 'qualification-documents' && this.qualificationType === 16) {
        this.getQualificationDiplomaProofByID({ id: this.sailorID, documentID: this.documents[0] }).then(response => {
          if (response?.code === 200) this.documentFrom = response.data
        }).finally(() => { this.isDocumentFromLoading = false })
      } else if (this.type === 'qualification-documents' && this.qualificationType !== 16) {
        this.getQualificationDocumentsByID({ id: this.sailorID, documentID: this.documents[0] }).then(response => {
          if (response?.code === 200) this.documentFrom = response.data
        }).finally(() => { this.isDocumentFromLoading = false })
      } else {
        this.getEducationDocumentByID({ id: this.sailorID, documentID: this.documents[0] }).then(response => {
          if (response?.code === 200) this.documentFrom = response.data
        }).finally(() => { this.isDocumentFromLoading = false })
      }
      // Load document TO
      this.isDocumentToLoading = true
      if (this.type === 'qualification-documents' && this.qualificationType === 16) {
        this.getQualificationDiplomaProofByID({ id: this.sailorID, documentID: this.documents[1] }).then(response => {
          if (response?.code === 200) this.documentTo = response.data
        }).finally(() => { this.isDocumentToLoading = false })
      } else if (this.type === 'qualification-documents' && this.qualificationType !== 16) {
        this.getQualificationDocumentsByID({ id: this.sailorID, documentID: this.documents[1] }).then(response => {
          if (response?.code === 200) this.documentTo = response.data
        }).finally(() => { this.isDocumentToLoading = false })
      } else {
        this.getEducationDocumentByID({ id: this.sailorID, documentID: this.documents[1] }).then(response => {
          if (response?.code === 200) this.documentTo = response.data
        }).finally(() => { this.isDocumentToLoading = false })
      }
    },

    mergeDocuments () {
      const body = {
        sailor_key: this.sailorID,
        content_type: '',
        old_document: this.documentFrom.id,
        new_document: this.documentTo.id
      }
      if (this.type === 'qualification-documents') {
        body.content_type = this.qualificationType === 16 ? 'proofofworkdiploma' : 'qualificationdocument'
      } else body.content_type = 'education'

      this.isDocumentsMerging = true
      this.$api.post(`api/v1/back_off/merge_documents/`, { body }).then(response => {
        if ([200, 201].includes(response?.code)) {
          this.$notification.success('successMerge')
          if (this.type === 'qualification-documents') this.$router.push({ name: 'qualification-documents', params: { id: this.sailorID } })
          else this.$router.push({ name: 'education-documents', params: { id: this.sailorID } })
        }
      }).finally(() => { this.isDocumentsMerging = false })
    }
  }
}
</script>
