<template>
  <div class="d-flex justify-end">
    <div
      :key="index"
      v-for="(sign, index) of signInfo"
      :class="sign.signature__type_signature === 'ST' ? 'img ml-5 img-stamp' : 'img ml-5 img-sign'">
      <div>
        <span>{{ sign.signature__name }}</span>
        <span>{{ $t('edrpou') }}</span>
        <span>{{ sign.signature__EDRPOU }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sign',
  props: {
    signInfo: Array
  }
}
</script>

<style scoped>
.img div {
  max-width: 130px;
  text-align: center;
  font-size: 24px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.img div span:first-of-type {
  margin-bottom: 10px;
}

.img div span {
  font-size: 13px;
}

.img-stamp {
  background: url('../../assets/stamp.svg');
  width: 250px;
  height: 250px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-background-size: cover;
  background-size: cover;
}

.img-sign {
  background: url('../../assets/sign.svg');
  width: 250px;
  height: 250px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-background-size: cover;
  background-size: cover;
}

.img-stamp div {
  color: #a20000;
}

.img-sign div {
  color: #3844ae;
}
</style>
