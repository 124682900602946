<template lang="pug">
include ../../../configs/template
div.row.text-left
  div.col-12
    +data-info('strictBlank', 'numberStrictBlank')
  div(v-if="sailorDocument.type_document !== 16 && !sailorDocument.other_number").col-12
    +data-info('number', 'sailorDocument.number')
  div(v-else-if="sailorDocument.other_number").col-12
    +data-info('number', 'sailorDocument.other_number')
  div.col-12
    +data-info('typeDoc', 'typeDocQualificationByID(sailorDocument.type_document)[nameLang]')
  div(v-if="this.sailorDocument.other_port || this.sailorDocument.port").col-12
    +data-info('port', 'port')
  div.col-sm-12.col-md-6
    +data-info('rank', 'rankByID(sailorDocument.rank)[nameLang]')
  div.col-sm-12.col-md-6
    b {{ $t('position') }}:
    span(v-for="position in sailorDocument.list_positions" :key="position.id") {{ positionByID(position)[nameLang] }};

  div(v-if="sailorDocument.type_document === 16").col-12.text-left
    b {{ $t('limitation') }}:
    div(v-for="(limitation, index) in sailorDocument.function_limitation" :key="index").mb-1.col-12.pa-0.pl-4
      div(v-if="limitation && limitation.id_limit && limitation.id_func")
        div {{ qualificationLimitationFunctionByID(limitation.id_func).function[nameLang] }}:
        div(v-if="limitation.id_limit.length")
          div(v-for="limit in limitation.id_limit" :key="limit") - {{ qualificationLimitationByID(limit)[nameLang] }};
        div(v-else) -{{ $t('notExist') }}
  div.col-sm-12.col-md-6
    +data-info('dateIssue', 'getDateFormat(sailorDocument.date_start)')
  div.col-sm-12.col-md-6(v-if="sailorDocument.renew_date_end && checkAccess('backoffice')")
    +data-info('renewDateEndInfo', 'getDateFormat(sailorDocument.renew_date_end)')
  div(v-if="[16, 21, 57, 85, 86, 88, 89].includes(sailorDocument.type_document)").col-sm-12.col-md-6
    +data-info('dateEnd', 'getDateFormat(sailorDocument.date_end)')
  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12.col-md-6
    +data-info('recordAuthor', 'sailorDocument.created_by.name')
  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12.col-md-6
    +data-info('createDate', 'sailorDocument.created_by.date')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").col-sm-12.col-md-6
    +data-info('verifier', 'sailorDocument.verificated_by.name')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").col-sm-12.col-md-6
    +data-info('verificationDate', 'sailorDocument.verificated_by.date')
  div(v-if="basedQualificationStatement && qualificationStatementById").col-12
    b {{ $t('onBasisOf', { number: qualificationStatementById.number }) }}
    router-link(:to="{ name: 'qualification-statements-info', params: { id: $route.params.id, documentID: sailorDocument.statement }}")  ({{ $t('openStatement') }})
  div.col-12
    +data-info('status', 'statusById(sailorDocument.status_document)[nameLang]')(:class="getStatus(sailorDocument.status_document)")
  div.col-12
    v-divider
  div(v-if="sailorDocument.comments?.length").col-12
    h5.p-0.m-0 {{$t('rejectionReasonDocument')}}:
    div.col-12.p-0
      v-divider
    div(v-for="(comment, index) in sailorDocument.comments" :key="comment.id").ml-4.text-left
      div
        +data-info('rejectionReasonDocument','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
      div(v-if="checkAccess('backOffice')").pt-2.py-3
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2.py-3
        +data-info('dateCreateDocument', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2.py-3
        +data-info('reasonComment', 'comment.comment')
      v-divider(v-if="index !== sailorDocument.comments.length-1")
  v-btn(
    v-if="checkAccess('qualification', 'showDocumentSavingBtn', sailorDocument) && sailorDocument.signatures_info?.length && checkAccess('downloadFile')"
    @click="saveDocument"
    :loading="isLoadDocumentSaving"
    color="primary").mr-3 {{ $t('saveDoc') }}
  v-btn.mr-2(
    v-if="checkAccess('statement_replace_qualification', 'add') && sailorDocument.status_document === validStatusQualificationDoc && !proofDocs.includes(sailorDocument.type_document)"
    color="success"
    @click="replacementStatement") {{$t('replacement-statement')}}
  v-btn(
    v-if="checkAccess('addEDS') && sailorDocument.can_sign"
    @click="setSignObj"
    :loading="isLoadSign"
    color="success").mr-3 {{ $t('setSign') }}

  v-btn(
    v-if="sailorDocument.signatures_info?.length && checkAccess('downloadFile')"
    @click="saveDocumentWithWatermark"
    :loading="isLoadWatermark"
    color="primary").mr-3 {{ $t('saveDocWithWatermark') }}
  v-btn(
    v-if="sailorDocument.is_without_sign && checkAccess('downloadFile')"
    @click="saveDocumentWithWatermarkWithoutSignature"
    :loading="isLoadWatermark"
    color="primary").mr-3 {{ $t('saveDocWithWatermark') }}

  v-btn(
    v-if="sailorDocument.signatures_info && sailorDocument.signatures_info.length && checkAccess('downloadFile')"
    @click="saveDocumentWithSign"
    :loading="isLoadWithSign"
    color="primary") {{ $t('saveDocWithSign') }}
  v-btn(
    v-if="false && sailorDocument.signatures_info && sailorDocument.signatures_info.length && checkAccess('downloadFile')"
    @click="handleClickResetDocument"
    :loading="isLoadResetDocument"
    color="primary") {{ $t('btn.resetDocument') }}
  div.col-12
    Sign(:signInfo="sailorDocument.signatures_info")
</template>

<script>
import { getDateFormat, getStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'

import { mapActions, mapState, mapGetters } from 'vuex'
import Sign from '@/components/atoms/Sign.vue'
import { validStatusQualificationDoc, SUCCESS_CODE } from '@/configs/constants'

export default {
  props: {
    comments: { type: Array, default: () => ([]) },
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    Sign
  },
  data () {
    return {
      checkAccess,
      getDateFormat,
      getStatus,
      isLoadSign: false,
      isLoadResetDocument: true,
      isLoadWatermark: false,
      isLoadWithSign: false,
      isLoadDocumentSaving: false,
      validStatusQualificationDoc,
      rejectStatusId: 37,
      proofDocs: [16]
    }
  },
  computed: {
    ...mapGetters(['typeDocQualificationByID', 'portByID', 'rankByID', 'positionByID', 'statusById',
      'qualificationLimitationByID', 'qualificationLimitationFunctionByID', 'rejectionReasonByID', 'getDirectoryObject'
    ]),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      qualificationStatementById: state => state.sailor.qualificationStatementById
    }),
    basedQualificationStatement () {
      if (this.sailorDocument.statement) {
        this.getQualificationStatementById({ id: this.$route.params.id, documentID: this.sailorDocument.statement })
        return true
      } else return false
    },
    numberStrictBlank () {
      if (this.sailorDocument.strict_blank) return `№ ${this.sailorDocument.strict_blank}`
      else {
        const number = this.sailorDocument.number_strict_blank?.toString().padStart(6, '0')
        return `${this.sailorDocument.letter_strict_blank} № ${number}`
      }
    },
    port () {
      if (!this.sailorDocument.other_port && this.sailorDocument.port) return this.portByID(this.sailorDocument.port)[this.nameLang]
      else return this.sailorDocument.other_port
    }
  },
  methods: {
    ...mapActions(['setSign', 'getDocument', 'setStatementReplaceQualification', 'setStatementReplaceProof',
      'getQualificationStatementById', 'getDocumentForSave', 'getArchiveDocument', 'getDocumentWithWotermark',
      'setSignQualificationDocument', 'getDocumentWithWotermarkWithSignature']),

    async saveDocument () {
      this.isLoadDocumentSaving = true
      let link = 'generate_qualification_specialist_certificate'
      if (this.sailorDocument.type_document === 16) link = 'generate_qualification_proof_of_diploma'
      else if ([1, 49].includes(this.sailorDocument.type_document)) link = 'generate_qualification_diploma'
      let data = {
        link,
        body: { document_id: this.sailorDocument.id, strict_blank: this.sailorDocument.strict_blank },
        number: this.sailorDocument?.number || this.sailorDocument.number_document
      }
      await this.getDocumentForSave(data)
      this.$notification.success('notify.success.downloadGenerateFile')
      this.isLoadDocumentSaving = false
    },

    async saveDocumentWithSign () {
      this.isLoadWithSign = true
      let body = {
        typeDocument: this.sailorDocument.type_document === 16 ? 'proof' : 'qualification',
        fileName: `Signed Document N ${this.sailorDocument.number}.zip`,
        contentType: 'application/zip',
        ...this.$route.params
      }
      await this.getArchiveDocument(body).finally(() => { this.isLoadWithSign = false })
      this.$notification.success('notify.success.downloadGenerateFile')
    },

    async saveDocumentWithWatermark () {
      this.isLoadWatermark = true
      let body = {
        typeDocument: this.sailorDocument.type_document === 16 ? 'proof' : 'qualification',
        fileName: `Copy of Document N ${this.sailorDocument.number}`,
        contentType: 'application/pdf',
        ...this.$route.params
      }
      await this.getDocumentWithWotermark(body)
      this.$notification.success('notify.success.downloadGenerateFile')
      this.isLoadWatermark = false
    },
    async saveDocumentWithWatermarkWithoutSignature () {
      this.isLoadWatermark = true
      let body = {
        typeDocument: this.sailorDocument.type_document === 16 ? 'proof' : 'qualification',
        fileName: `Copy of Document N ${this.sailorDocument.number}`,
        contentType: 'application/pdf',
        ...this.$route.params
      }
      await this.getDocumentWithWotermarkWithSignature(body)
      this.$notification.success('notify.success.downloadGenerateFile')
      this.isLoadWatermark = false
    },

    async setSignObj () {
      this.isLoadSign = true
      let data = {
        body: { document: this.sailorDocument.id },
        typeDocument: this.sailorDocument.type_document === 16 ? 'proof' : 'qualification'
      }
      await this.setSignQualificationDocument(data)
      this.isLoadSign = false
    },
    replacementStatement () {
      this.$swal({
        icon: 'info',
        title: `${this.$t('Do you want replace document')} ?`,
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async (confirm) => {
        let data = {
          ...this.$route.params
        }
        if (confirm) {
          if (this.sailorDocument.diploma) {
            data.documentId = this.sailorDocument.id
            const response = await this.setStatementReplaceProof(data)
            if (response) this.$router.go(-1)
          } else {
            const response = await this.setStatementReplaceQualification(data)
            if (SUCCESS_CODE.includes(response.code)) this.$router.go(-1)
          }
        }
      })
    },
    handleClickResetDocument () {
      this.$swal({
        title: this.$t('do you sure that you want to reset document?'),
        buttons: [this.$t('no'), this.$t('yes')],
        icon: 'info'
      })
    }
  }
}
</script>
